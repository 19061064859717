import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'

const Trabajo = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- about -->
            }

            <div className="ourwork">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Our Work</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our.jpg" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our1.jpg" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our.jpg" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our1.jpg" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our.jpg" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="our_box">
                                <figure><img src="imgs/our1.jpg" alt="#" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                //<!--  footer -->
            }
            <Footer></Footer>
        </div>
    )
}

export default Trabajo