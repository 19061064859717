import React from 'react'
import Header from "../../componentes/Header";
import { Link, NavLink } from "react-router-dom";
import './visualizador.css'
import MapView from '../../componentes/MapView';

const Visualizador = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-end">
                        <div className='col-1'>
                            <Link to={'/Reportes'}>
                                <button type="button" className="btn btn-secondary">Reportes</button>
                            </Link>
                        </div>

                        <div className='col-1'>
                            <Link to={'/Graficas'}>
                                <button type="button" className="btn btn-secondary">Graficas</button>
                            </Link>
                        </div>
                        <div className='col-1'>
                            <Link to={'/Visualizador'}>
                                <button type="button" className="btn btn-secondary">Mapa</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <h2 className="titulo">Visualizador</h2>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="container-fluid shadowsM">
                               <MapView></MapView>
                            </div>
                        </div>
                    </div>                    
                </div >
            </section >
        </div >
    )
}

export default Visualizador