
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Clientes from './pages/Clientes/Clientes';
import Trabajo from './pages/Ntrabajo/Trabajo';
import Contacto from './pages/Contacto/Contacto';
import Login from './pages/Login/Login'
import Registro from './pages/Registro/Registro'
import Forgot from './pages/Password/Forgot'
import Mapa from './pages/Mapa/Mapa'
import Reportes from './pages/Reportes/Reportes'
import Graficas from './pages/Graficas/Graficas'
import Visualizador from './pages/Visualizador/Visualizador'



function App() {
  return (
    <div className="container-fluid">
      <Routes>
          <Route path='/'>
            <Route index element={<Home></Home>}></Route>
          </Route>
          <Route path={`/about`}>
            <Route index element={<About></About>} />
          </Route>
          <Route path={`/clientes`}>
            <Route index element={<Clientes></Clientes>} />
          </Route>
          <Route path={`/ntrabajo`}>
            <Route index element={<Trabajo></Trabajo>} />
          </Route>
          <Route path={`/contacto`}>
            <Route index element={<Contacto></Contacto>} />
          </Route>
          <Route path={`/login`}>
            <Route index element={<Login></Login>} />
          </Route>
          <Route path={`/Registro`}>
            <Route index element={<Registro></Registro>}></Route>
          </Route>
          <Route path={`/Forgot`}>
            <Route index element={<Forgot></Forgot>}></Route>
          </Route>
          <Route path={`/mapa`}>
            <Route index element={<Mapa></Mapa>}></Route>
          </Route>
          <Route path={`/Reportes`}>
            <Route index element={<Reportes></Reportes>}></Route>
          </Route>
          <Route path={`/Graficas`}>
            <Route index element={<Graficas></Graficas>}></Route>
          </Route>
          <Route path={`/Visualizador`}>
            <Route index element={<Visualizador></Visualizador>}></Route>
          </Route>
      </Routes>
    </div>
  );
}

export default App;