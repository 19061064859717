import React, { useState, useEffect, useContext } from "react";
import {
  MapContainer,
  ScaleControl,
  LayersControl,
  TileLayer,
  ZoomControl,
  WMSTileLayer,
  FeatureGroup
} from "react-leaflet";

import $ from "jquery";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./jquery-sidebar.js";
import "./leaflet-sidebar.js";
import { EditControl } from 'react-leaflet-draw'

import { ContextoCreadoFeature } from '../context/contextoFeatureGroupDibujadas'
import "leaflet/dist/leaflet.css";
import 'leaflet-draw/dist/leaflet.draw.css'
import '../css/L.Control.BetterScale.css';
import '../css/leaflet.scalefactor.css';
import '../css/leaflet-sidebar.css';
import "../css/style.css"

import {
  faFileCsv,
  faDownload,
  faBars,
  faArrowLeft,
  faHome,
  faLayerGroup,
  faChartBar,
  faDashboard,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";





//variables globales
var info, _div;
const { BaseLayer } = LayersControl;

const MapView = () => {

  //Parte del codigo que hace referencia al mapa
  const [mapa, setMapa] = useState(null);

  const featureContexto = useContext(ContextoCreadoFeature)


  useEffect(() => {
    if (mapa !== null) {
      L.control.sidebar('sidebar').addTo(mapa);
      //info = L.control();
      //_div = L.DomUtil.create("div", "info"); // create a div with a class "info"

      setMapa(mapa);
    }
  }, [mapa]);

  useEffect(() => {
    let lDrLo = L.drawLocal;
    lDrLo.draw.toolbar.buttons.polyline = "Dibujar una linea"
    lDrLo.draw.toolbar.buttons.polygon = "Dibujar un poligono"
    lDrLo.draw.toolbar.buttons.marker = "Dibujar un marcador"
    lDrLo.draw.toolbar.buttons.rectangle = "Dibujar un rectangulo";
    lDrLo.draw.handlers.rectangle.tooltip.start = "Mantener clic y arrastrar para dibujar";
    lDrLo.draw.handlers.circle.tooltip.start = "Mantener clic y arrastrar para identificar capas";
    lDrLo.draw.handlers.circle.radius = "Radio";
    lDrLo.draw.handlers.simpleshape.tooltip.end = "Dejar de hacer clic para finalizar";
    lDrLo.draw.handlers.polyline.error = "<strong>¡Error:</strong> las esquinas de la forma no se pueden cruzar!"
    lDrLo.draw.toolbar.actions.title = "Cancelar dibujo"
    lDrLo.draw.toolbar.actions.text = "Cancelar"
    lDrLo.draw.toolbar.finish.title = "Finalizar dibujo"
    lDrLo.draw.toolbar.finish.text = "Finalizar"
    lDrLo.draw.toolbar.undo.title = "Borrar último punto dibujado"
    lDrLo.draw.toolbar.undo.text = "Borrar último punto"
    lDrLo.draw.handlers.marker.tooltip.start = "Clic en el mapa para poner un marcador"
    lDrLo.draw.handlers.polygon.tooltip.start = "Clic para empezar a dibujar una forma"
    lDrLo.draw.handlers.polygon.tooltip.cont = "Clic para continuar dibujando la forma"
    lDrLo.draw.handlers.polygon.tooltip.end = "Clic en el primer punto para cerrar esta forma"
    lDrLo.draw.handlers.polyline.tooltip.start = "Clic para empezar a dibujar una linea"
    lDrLo.draw.handlers.polyline.tooltip.cont = "Clic para continuar dibujando la linea"
    lDrLo.draw.handlers.polyline.tooltip.end = "Clic en el último punto para finalizar la linea"
    lDrLo.edit.toolbar.actions.save.title = "Guardar cambios"
    lDrLo.edit.toolbar.actions.save.text = "Guardar"
    lDrLo.edit.toolbar.actions.cancel.title = "Cancelar edición, descartar todos los cambios"
    lDrLo.edit.toolbar.actions.cancel.text = "Cancelar"
    lDrLo.edit.toolbar.actions.clearAll.title = "Limpiar todo"
    lDrLo.edit.toolbar.actions.clearAll.text = "Limpiar todo"
    lDrLo.edit.toolbar.buttons.edit = "Editar polígonos"
    lDrLo.edit.toolbar.buttons.editDisabled = "No hay elementos para editar"
    lDrLo.edit.toolbar.buttons.remove = "Limpiar mapa"
    lDrLo.edit.toolbar.buttons.removeDisabled = "No hay elementos para eliminar"
    lDrLo.edit.handlers.edit.tooltip.text = "Arrastre controladores o marcadores para editar características"
    lDrLo.edit.handlers.edit.tooltip.subtext = "Clic en cancelar para deshacer los cambios"
    lDrLo.edit.handlers.remove.tooltip.text = "Clic en la figura para borrarla"

  }, [])

  const [sigueEjecutando, setSigueEjecutando] = useState(true)

  function grupoDibujos(e) {
    if (e != null) {
      setSigueEjecutando(false)
      setTimeout(() => {
        featureContexto.setValorFeature(e)
      }, 5000)
    }
  }
  function acordeon(event) {
    if (event.name === "home") {
      document.getElementById("sidebar").classList.toggle("collapsed")
      document.getElementById("home").classList.toggle("active")
      document.getElementById("home1").classList.toggle("active")

    }
  }

  function cerrar() {
    document.getElementById("sidebar").classList.toggle("collapsed")
    document.getElementById("home").classList.toggle("active")
    document.getElementById("home1").classList.toggle("active")

  }



  return (
    <>
      <div className="contenedor">
        <div id="sidebar" className="sidebar collapsed">
          <div className="sidebar-tabs">
            <ul role="tablist">
              <li id="home1">
                <a name="home" role="tab" onClick={(e) => acordeon(e.target)}>
                  <FontAwesomeIcon
                    icon={faBars}
                    size="1x" />

                </a>
              </li>
              <li id="opcion2">
                <a name="opcion2" role="tab" onClick={(e) => acordeon(e.target)}>
                  <FontAwesomeIcon
                    icon={faHome}
                    size="1x" />
                </a>
              </li>
              <li id="opcion3">
                <a name="opcion3" role="tab" onClick={(e) => acordeon(e.target)}>
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    size="1x" />
                </a>
              </li>
              <li id="opcion4">
                <a name="opcion4" role="tab" onClick={(e) => acordeon(e.target)}>
                  <FontAwesomeIcon
                    icon={faDashboard}
                    size="1x" />
                </a>
              </li>
              <li id="opcion4">
                <a name="opcion4" role="tab" onClick={(e) => acordeon(e.target)}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    size="1x" />
                </a>
              </li>

            </ul>
          </div>

          {
            //<!-- Tab panes -->
          }
          <div className="sidebar-content">
            <div className="sidebar-pane" id="home">
              <div className="container-fluid">
                <h1 className="sidebar-header">
                  Menu
                  <span className="sidebar-close" onClick={cerrar}>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="1x" />
                  </span>
                </h1>
                <br />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

              </div>

            </div>
          </div>

        </div>








        <MapContainer
          //20.68924342376687, -88.85523520325107
          //20.817975372868176, -88.66845180043808
          //20.250724780155917, -88.77620730149292
          center={[24.26825996870948, -102.88361673036671]}
          zoom={5}
          zoomDelta={0.25}
          zoomSnap={0.25}
          zoomControl={false}
          doubleClickZoom={false}
          whenCreated={setMapa}
          minZoom={5.75}
          maxBounds={[
            [34, -86],
            [14, -120],
          ]}
          maxBoundsViscosity={0.7}
        >
          <ZoomControl
            position="topright"
            zoomInTitle="Acercar"
            zoomOutTitle="Alejar"
          />
          <ScaleControl maxWidth="100" position={"bottomright"} />
          <LayersControl>
            <BaseLayer checked name="Open street map">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="INEGI">
              <WMSTileLayer
                url="http://gaiamapas2.inegi.org.mx/mdmCache/service/wms?"
                layers="MapaBaseTopograficov61_sinsombreado_gris"
              />
            </BaseLayer>
            <BaseLayer name="ESRI calles">
              <TileLayer
                attribution='&copy; <a href="#">ESRI</a> contributors'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="ESRI Satélite">
              <TileLayer
                attribution='&copy; <a href="#">WorldImagery</a> contributors'
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="Topográfico">
              <TileLayer
                attribution='&copy; <a href="#">opentopomap</a> contributors'
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>
          <FeatureGroup ref={(e) => grupoDibujos(e)}>
            <EditControl
              position='topright'
              draw={{
                circlemarker: false,
              }}
            >
            </EditControl>
          </FeatureGroup>
        </MapContainer>
      </div>




    </>

  )
}

export default MapView