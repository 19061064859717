import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'
import emailjs from '@emailjs/browser'
import $ from "jquery"
import Swal from 'sweetalert2'
import './contacto.css'

const contacto = () => {

  function enviarWhats() {
    console.log("Se envia whatsapp")
    let numero = +527292453600;
    let mensaje = 'Hola, estoy interesado en tus servicios.'
    var win = window.open(`https://wa.me/${numero}?text=${mensaje}`, '_blank');

  }

  function enviarCorreo() {
    //console.log("Se envia correo")
    let nombre = document.getElementById("fulln").value
    let tel = document.getElementById("tel").value
    let mail = document.getElementById("mail").value
    let msj = document.getElementById("msj").value
    console.log(nombre)
    //emailjs.sendForm('service_rmirwkh','template_i8cly1n')
    var data = {
      service_id: 'service_rmirwkh',
      template_id: 'template_i8cly1n',
      user_id: 'k_H8JPp1YXq_MO2SB',
      template_params: {
        'nombre': nombre,
        'tel': tel,
        'mail': mail,
        'msj': msj,
      }
    }

    $.ajax('https://api.emailjs.com/api/v1.0/email/send', {
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json'
    }).done(function (response) {
      if (response === "OK") {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Mensaje enviado correctamente',
          showConfirmButton: false,
          timer: 1500
        })
        document.getElementById("fulln").value = ""
        document.getElementById("tel").value = ""
        document.getElementById("mail").value = ""
        document.getElementById("msj").value = ""
      }

    }).fail(function (error) {
      console.log(error)
    });

  }


  return (
    <div className="container-fluid">
      <Header></Header>
      {
        //<!-- about -->
      }

      <div id="contact" class="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <h2>Solicitar una devolución de llamada</h2>
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <div id="request" class="main_form">
                <div className="row">
                  <div className="col-md-12 ">
                    <input className="contactus" placeholder="Nombre completo" type="type" name="Full Name" id="fulln" required />
                  </div>
                  <div className="col-md-12">
                    <input className="contactus" id="tel" placeholder="Número de teléfono" type="type" name="Phone Number" />
                  </div>
                  <div className="col-md-12">
                    <input className="contactus" id="mail" placeholder="Email" type="type" name="Email" />
                  </div>
                  <div className="col-md-12">
                    <textarea className="contactus" id="msj" placeholder="Mensaje" type="type" Message="Name"></textarea>
                  </div>
                  <div className="col-sm-12">
                    <button className="send_btn" onClick={enviarCorreo}>Enviar correo</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="row d_flex">
            <div className="col-md-12">
              <div className="titlepage" onClick={enviarWhats}>
                <img src="imgs/whatsapp.png" alt="whatsapp" className='img-fluid imgW' />
                <h2>Enviar Whastapp</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        //<!--  footer -->
      }
      <Footer></Footer>
    </div>
  )
}

export default contacto