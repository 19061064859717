import React, { useState } from "react";
import Footer from "../../componentes/Footer";
import Header from "../../componentes/Header";
import './clientes.css'

const Clientes = () => {

    const [imagenM, setImagenM] = useState("");
    function imagenMostrar(opcion) {
        //console.log(opcion)
        setImagenM(opcion)

    }
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- about -->
            }

            <div class="clients">
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Soluciones</h2>
                                <p>Al adquirir <strong>GeoMark</strong> se tiene acceso a novedosas y potenciales herramientas que permiten:</p>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9 offset-md-1">
                            <div className="titlepage">
                                <h2>Visualice su información en un solo sitio</h2>
                                <span></span>
                                <p>Sintetiza y analiza cantidades de datos, al adquirir GeoMark se tiene acceso a novedosas y potenciales herramientas que permiten:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" onClick={() => imagenMostrar("conoce")}>
                                            Conoce mejor a la competencia y a clientes potenciales
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Identifica a tus competidores directos, en cuentra el nicho de mercado mal atendido y reconoce aquellos elementos que te diferencian de la competencia.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" onClick={() => imagenMostrar("mayor")}>
                                            Mayor presencia y visibilidad
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Hacer que el mercado objetivo sepa que existes, te encuentre y descubra lo que ofreces.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" onClick={() => imagenMostrar("ayuda")}>
                                            Ayuda al manejo efectivo de la gerencia administrativa que a su vez coadyuva a mejorar los procesos dentro de la empresa
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Mejora la eficiencia y calidad del trabajo automatizando y estandarizando procesos manteniendo el nivel de productividad de la organización con el fin de acelerar resultados y obtener ventajas competitivas en el mercado.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" onClick={() => imagenMostrar("visualizacion")}>
                                            Visualización de datos de manera grafica
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Al utilizar elementos visuales las herramientas de visulización de datos proporcionan una manera accesible de ver y comprender tendencias, valores atípicos y patrones en los datos.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" onClick={() => imagenMostrar("conoce")}>
                                            Conoce en profundidad las condiciones del mercado actual
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Concede a la empresa la información que necesitas para apuntar a las audiencias con las que quiere conectar, permite a las empresas toar decisiones bien informadas, eliminar las conjeturas y canalizar recursos en ideas y proyectos que tienen el mayor potencial.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" onClick={() => imagenMostrar("reconoce")}>
                                            Reconoce puntos de localización de clientes
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            El geoposicionamiento ayuda a determinar o eliminar la posicion geográfica en este caso de la competencia y posibles clientes, abre puertas de personalización de estrategias de marketing y conseguir que el cliente nos encuentre por su emplazamiento y necesidades concretas.
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-5">
                            {
                                imagenM === "" ? (
                                    <img src="imgs/solutions.png" alt="Soluciones" className="img-fluid imgSolu" />
                                ) : (
                                    imagenM==="conoce"?(
                                        <img src="imgs/competencia.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                    ):(
                                        imagenM==="mayor"?(
                                            <img src="imgs/visibilidad.jpg" alt="Mayor visibilidad" className="img-fluid imgSolu" />
                                        ):(
                                            imagenM==="ayuda"?(
                                                <img src="imgs/administracion.jpg" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                            ):(
                                                imagenM==="visualizacion"?(
                                                    <img src="imgs/graficas.jpg" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                ):(
                                                    imagenM==="conoce"?(
                                                        <img src="imgs/mercado.jpg" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                    ):(
                                                        imagenM==="reconoce"&&(
                                                            <img src="imgs/localizacion.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                        )        
                                                    )    
                                                )
                                            )
                                        )
                                    )
                                    
                                )
                            }


                        </div>
                    </div>
                </div>
            </div>
            <br />

            {
                //<!--  footer -->
            }
            <Footer></Footer>
        </div>
    );
};

export default Clientes;
