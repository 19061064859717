import React from "react";
import { Link ,NavLink} from "react-router-dom";
import './header.css'

const Header = () => {
    return (
        <>
            {/*
            <header className="container-fluid bg-primary d-flex justify-content-center">
                <p className='text-light mb-0 p-2'>Contactanos 1-(305)-725-1000</p>
            </header>
            */}

            <nav
                className="navbar navbar-expand-lg bg-light justify-content-between"
                id="menu"
            >
                <div className="container-fluid">
                    <NavLink to={'/'} className={({isActive})=> isActive ? 'active' : ''}>
                        <a href="#">
                            <img src="imgs/logo.svg" alt="#" className="img-fluid" width={100} />
                        </a>

                    </NavLink>
                    {
                        //<a className="navbar-brand" href="#">GEOMarketing</a>
                    }
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                            <NavLink to={"/about"} className={({isActive})=> isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" aria-current="page">
                                        Acerca de
                                    </a>
                                </li>
                            </NavLink>

                            <NavLink to={"/clientes"} className={({isActive})=> isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Soluciones
                                    </a>
                                </li>
                            </NavLink>

                            <NavLink to={"/Registro"} className={({isActive})=> isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Compra
                                    </a>
                                </li>
                            </NavLink>
                            <NavLink to={"/contacto"} className={({isActive})=> isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Contacto
                                    </a>
                                </li>
                            </NavLink>
                            {
                                /*
                                   <li>
                                <form className="d-flex" role="search">
                                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                                </form>

                            </li>
                                */
                            }
                         
                            <NavLink to={"/login"} id='link' className={({isActive})=> isActive ? 'active2' : ''}>
                                <div>
                                    <a href="#">
                                        <img src="imgs/personas.png" alt="User" className=" img-fluid userI" />
                                    </a>
                                </div>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
