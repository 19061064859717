import {createContext, useState} from 'react'

//Se define el contexto
export const ContextoCreadoFeature = createContext()

//Regresa el proveedor del contexto
export default function contextoFeatureGroupDibujadas(componentesHijos){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [valorFeature, setValorFeature] = useState(null)

    return(
        <ContextoCreadoFeature.Provider value={{valorFeature, setValorFeature}}>
            {componentesHijos.children}
        </ContextoCreadoFeature.Provider>
    )
}