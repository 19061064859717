import React from "react";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row border-top">
                        <div className="col-md-2 padding_bottom1">
                            <img
                                src="imgs/logob.svg"
                                alt="#"
                                className="img-fluid"
                                width={150}
                            />
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>EMPRESA</h3>
                            <ul className="cont">
                                <Link to={"/about"} className={({ isActive }) => isActive ? 'active' : ''}>
                                    <li className="nav-item">
                                        <a className="nav-link itemH" aria-current="page">
                                            Acerca de
                                        </a>
                                    </li>
                                </Link>
                                <Link to={"/clientes"} className={({ isActive }) => isActive ? 'active' : ''}>
                                    <li className="nav-item">
                                        <a className="nav-link itemH" href="#">
                                            Soluciones
                                        </a>
                                    </li>
                                </Link>
                                <li>
                                    Preguntas Frecuentes
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 padding_bottom1">
                            <h3>CONTACTO</h3>
                            <ul className="cont">
                                <li>
                                    7291400075
                                </li>
                                <li>
                                    7225856709
                                </li>
                                <li>
                                    e-mail: geomarket.223@gmail.com
                                </li>
                                <li>
                                    geomark.contacto@gmail.com
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>PRODUCTOS Y SERVICIOS</h3>
                            <ul className="cont">
                                <li>
                                    Bases de Datos
                                </li>
                                <li>
                                    Reportes
                                </li>
                                <li>
                                    Gráficas
                                </li>
                                <li>
                                    Mapas
                                </li>
                                <li>
                                    Consultoria
                                </li>
                                <li>
                                    Manual
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>PRIVACIDAD</h3>
                            <ul className="cont">
                                <li data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    Información Legal
                                </li>
                                <li>
                                    Condiciones de Uso
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <p>© 2022 All Rights Reserved.</p>
                            </div>
                            <div className="col-md-1">
                                <img
                                    src="imgs/inst.png"
                                    alt="#"
                                    className="img-fluid"
                                    width={"60%"}
                                />
                            </div>
                            <div className="col-md-1">
                                <img
                                    src="imgs/facebook.png"
                                    alt="#"
                                    className="img-fluid"
                                    width={"60%"}
                                />
                            </div>
                            <div className="col-md-1">
                                <img
                                    src="imgs/link.png"
                                    alt="#"
                                    className="img-fluid"
                                    width={"60%"}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Aviso de Privacidad</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h1>Resumen de nuestra Política de Privacidad</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec consectetur, ipsum non tristique elementum, nunc lorem facilisis lorem, ut fringilla ligula turpis ac urna. Mauris pulvinar vel odio ut ullamcorper. Donec tempor odio eu malesuada ullamcorper. Nunc lacinia condimentum metus sed posuere. Mauris ultrices vel neque vitae lacinia. Proin nec gravida dui. Sed lobortis velit sapien, a varius quam consequat dictum. Cras ultricies dolor non viverra posuere. </p>
                            <br />
                            <h3>Parte I – Información que GeoMark recopila y controles</h3>
                            <p>Proin mollis diam urna, et vulputate ante molestie in. Aenean sit amet eleifend orci, vel accumsan mi. Maecenas id porta nisi. Nullam vitae tristique est. Sed magna metus, fermentum a sagittis nec, dignissim sed felis. Phasellus ac eros eu lectus blandit interdum. Duis fermentum, erat vitae finibus pharetra, urna mauris hendrerit purus, vitae hendrerit purus magna in metus. Aliquam tincidunt id nulla non placerat. Morbi laoreet vel nisi vel pharetra. Duis aliquet pellentesque viverra. Aenean finibus tincidunt diam, vel semper metus tincidunt nec.</p>
                            <br />
                            <p>Sed pretium vitae tellus vitae fringilla. Donec vitae quam faucibus lacus hendrerit commodo id eu sapien. In non metus sed est sagittis venenatis. Donec hendrerit dapibus mauris, nec viverra mi mattis ac. Quisque a ante nulla. Nullam vulputate eleifend facilisis. Nunc dui dui, egestas vitae accumsan eget, finibus sit amet elit. Praesent ornare massa nec magna bibendum fermentum. Morbi dolor ante, sagittis in eros sit amet, hendrerit fringilla nunc. Integer pulvinar egestas ante, sed tempus metus consequat in. Praesent dignissim lacinia quam, quis dapibus orci vehicula nec. Nullam nec posuere tellus, et posuere nisi. Vivamus non molestie ex, eu bibendum ipsum. In est nulla, placerat sed leo id, gravida hendrerit leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            <br />
                            <p>Phasellus malesuada faucibus felis at ultricies. Etiam laoreet condimentum tortor id cursus. Phasellus eros tellus, dapibus ut nisl non, cursus pulvinar est. Proin quam sem, sollicitudin sed euismod quis, malesuada a ligula. Vivamus pharetra ac ex ac blandit. Aenean at congue nisi. Etiam a rhoncus mauris. Donec at velit est. Mauris ac neque laoreet, molestie nisl vel, congue urna. </p>
                            <br />
                            <h3>Qué hacemos con su información</h3>
                            <p>Proin pulvinar placerat purus eu porta. Nullam ut ipsum a leo ornare efficitur. Nulla commodo justo ut nisl hendrerit, et posuere ligula facilisis. Etiam pharetra tellus et dolor lobortis, et venenatis nisi rutrum. Maecenas aliquet bibendum ipsum vitae finibus. Nam euismod justo eu nunc congue pellentesque. Quisque suscipit ex at urna vehicula pellentesque. Donec nec orci ac est mattis dapibus ut id nisi. In ullamcorper fermentum orci. Vivamus venenatis, nibh ac lacinia luctus, eros mi scelerisque sem, eu ultricies tellus dui in turpis. Mauris mollis purus ut consequat viverra.</p>
                            <br />
                            <p>Cras eu luctus augue. In ornare ullamcorper mauris sed consectetur. Etiam vitae dolor metus. Fusce at risus vitae sapien placerat bibendum. Nullam in molestie dui, ac aliquet ipsum. Maecenas vitae arcu eu ligula posuere imperdiet at eget arcu. Suspendisse rhoncus lorem id enim finibus rhoncus. Nullam pharetra, ante vel consectetur volutpat, dui risus accumsan augue, sed hendrerit tortor ipsum at elit. Pellentesque viverra id tellus pulvinar gravida. Sed molestie tellus vitae vestibulum venenatis. </p>
                            <br />
                            <p>Donec non urna cursus, ultrices nulla at, semper diam. Etiam ut velit in felis tristique fringilla eget et lacus. Nunc vel urna molestie, sollicitudin magna vitae, pulvinar odio. Pellentesque sed dolor metus. Aenean eu urna aliquet, iaculis sem in, auctor nisi. Morbi ligula justo, pretium nec blandit eget, venenatis sed diam. Ut congue sodales magna. Mauris ultricies nisl sed laoreet mollis. In hac habitasse platea dictumst. </p>
                            <br />
                            <p>Pellentesque sit amet enim vitae diam hendrerit molestie. Etiam tincidunt neque vel enim consequat, vitae posuere justo posuere. Cras lectus velit, viverra quis lobortis at, pretium at purus. Aenean nec mattis augue. Proin nec justo sagittis, ultrices sem in, mattis dui. Nunc aliquam consequat urna eget lacinia. In aliquet felis at consequat consectetur. Aliquam laoreet nec ligula id condimentum. Nullam eget nulla at nibh mollis luctus sit amet at libero. Suspendisse nec ipsum eu felis posuere finibus venenatis eget quam. Vivamus a rutrum sem. Donec vehicula quis leo sed iaculis. </p>
                            <br />
                            <h3>Parte II – Información que GeoMark utiliza en los procesos en su beneficio</h3>
                            <p>In porta metus vel eros scelerisque cursus. Maecenas facilisis euismod dolor, sed suscipit nunc porttitor fringilla. Pellentesque sit amet mauris lorem. Nulla facilisis aliquet lacus eget venenatis. Sed lacinia pellentesque nulla ac maximus. Donec hendrerit at lectus at tempor. Integer ac quam aliquam, vehicula nunc sit amet, ultricies odio. Fusce pretium, odio in pulvinar bibendum, lectus velit finibus leo, ac ultrices urna nunc venenatis sapien. </p>
                            <br />
                            <p>Aliquam eu sem ipsum. Sed ac nulla eget orci malesuada imperdiet. Curabitur vel risus ullamcorper, vestibulum eros a, auctor felis. Ut ultrices, felis quis ornare suscipit, nunc turpis porttitor mi, aliquam cursus sapien dolor vitae nisl. Duis porttitor finibus leo ac blandit. Suspendisse pellentesque odio ante, quis sagittis lacus auctor at. Curabitur imperdiet tristique mauris, vitae rhoncus orci imperdiet sit amet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In lacinia diam sed augue tristique, id semper ante luctus. Aenean aliquet diam at ex fermentum malesuada. Nam sapien nisl, vestibulum non consequat sit amet, pulvinar rutrum eros. Aenean urna dolor, efficitur at urna ut, tristique porttitor ante. Nunc elementum pulvinar eros. </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
