import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'

const About = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- about -->
            }

            <div id="about" className="about" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ab_bg.png'})` }}>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>¿Qué es el Geomarketing?</h2>
                                <span></span>
                                <p>Es un sistema integrado por datos, programas informáticos de tratamiento, metodos estadisticos y representación gráfica destinada a producir información útil para la toma de decisiones, a traves de instrumentos que combinan cartografía digital, gráficos y tablas.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d_flex">
                        <div className="col-md-7">
                            <div className="titlepage">
                                <h2>ACERCA DE</h2>
                                <span></span>
                                <p><strong>GeoMark</strong> es una herramienta de visualizacion de datos espaciales y no espaciales que permite representar, ordenar y analizar datos de forma gráfica, ordenada y actualizada por medio de un software con conexión a internet.</p>
                                <p>Analiza grandes cantidades de datos con el fin de mejorar la toma de decisiones, simplificar procesos, aumentar su ventaja competitiva de acuerdo con sus necesidades e intereses.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="about_img img-fluid">
                                <figure><img src="imgs/geomarketing.png" alt="#" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //<!--  footer -->
            }
            <Footer></Footer>

        </div>
    )
}

export default About