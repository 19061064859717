import React from 'react'
import './forgot.css'
import Header from "../../componentes/Header";
import { Link, NavLink } from "react-router-dom";

const Login = () => {
  return (
    <div className="container-fluid">
      <Header></Header>
      <br />
      <section className="vh-99">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8 col-lg-5 col-xl-4">
              <img src="/imgs/olvide.png"
                className="img-fluid" alt="Sample image" />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form>
                {
                  /*
                  <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <p className="lead fw-normal mb-0 me-3">Sign in with</p>
                  <button type="button" className="btn btn-primary btn-floating mx-1">
                    <i className="fab fa-facebook-f"></i>
                  </button>

                  <button type="button" className="btn btn-primary btn-floating mx-1">
                    <i className="fab fa-twitter"></i>
                  </button>

                  <button type="button" className="btn btn-primary btn-floating mx-1">
                    <i className="fab fa-linkedin-in"></i>
                  </button>
                </div>

                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0">Or</p>
                </div>
                  */
                }



                <div className="form-outline mb-4">
                  <input type="email" id="correo1" className="form-control form-control-lg"
                    placeholder="Ingresa un email valido" />
                  <label className="form-label" for="correo1">Correo electrónico</label>
                </div>

                <div className="form-outline mb-4">
                  <input type="email" id="correo2" className="form-control form-control-lg"
                    placeholder="Ingresa un email valido" />
                  <label className="form-label" for="correo2">Confirma correo electrónico</label>
                </div>

                <div className="text-center text-lg-start mt-4 pt-2">
                  <button type="button" className="btn btn-primary btn-lg estilo" >Enviar</button>
                </div>

              </form>
            </div>
          </div>
        </div >
        {
          /*
          <div
          className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
        
          <div className="text-white mb-3 mb-md-0">
            Copyright © 2020. All rights reserved.
          </div>
        
        
        
          <div>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-google"></i>
            </a>
            <a href="#!" className="text-white">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        
        </div>
        
          */

        }

      </section >
    </div >
  )
}

export default Login