import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'
import './home.css'
import emailjs from '@emailjs/browser'
import $ from "jquery"

import Swal from 'sweetalert2'

const Home = () => {

    function enviarCorreo() {
        
        let nombre = document.getElementById("fulln").value
        let tel = document.getElementById("tel").value
        let mail = document.getElementById("mail").value
        let msj = document.getElementById("msj").value
        
        //emailjs.sendForm('service_rmirwkh','template_i8cly1n')
        var data = {
            service_id: 'service_rmirwkh',
            template_id: 'template_i8cly1n',
            user_id: 'k_H8JPp1YXq_MO2SB',
            template_params: {
                'nombre': nombre,
                'tel': tel,
                'mail': mail,
                'msj': msj,
            }
        }

        $.ajax('https://api.emailjs.com/api/v1.0/email/send', {
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json'
        }).done(function (response) {
            if (response === "OK") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Mensaje enviado correctamente',
                    showConfirmButton: false,
                    timer: 1500
                })
                document.getElementById("fulln").value =""
                document.getElementById("tel").value=""
                document.getElementById("mail").value=""
                document.getElementById("msj").value=""
            }

        }).fail(function (error) {
            console.log(error)
        });

    }


    return (
        <div className="container-fluid">
            <Header></Header>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="imgs/banner.jpg" className="d-block w-100" alt="imagen 1" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3>First slide label</h3>
                            <p>Some representative placeholder content for the first slide.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="imgs/banner2.jpg" className="d-block w-100" alt="imagen 2" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3>Second slide label</h3>
                            <p>Some representative placeholder content for the second slide.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="imgs/banner3.jpg" className="d-block w-100" alt="imagen 3" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3>Third slide label</h3>
                            <p>Some representative placeholder content for the third slide.</p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div id="about" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ab_bg.png'})` }} className='about'>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-7">
                            <div className="titlepage">
                                <h2>Web Development</h2>
                                <span></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                <a className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="about_img">
                                <figure><img src="imgs/about_img.png" alt="#" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="mobile" className="mobile" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/mo_bg.jpg'})` }}>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-5">
                            <div className="mobile_img">
                                <figure><img src="imgs/mobile.png" alt="#" /></figure>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="titlepage">
                                <h2>Mobile App Development</h2>
                                <span></span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                <a className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="clients">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="titlepage">
                                <h2>What is Say clients</h2>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clients_box">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                            </div>
                            <div className="jonu">
                                <img src="imgs/cross_img.png" alt="#" />
                                <h3>Jone due</h3>
                                <strong>(sure there isn't)</strong>
                                <a className="read_more" href="#">Get A Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                //Contact us
            }

            <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Solicitar una devolución de llamada</h2>
                            </div>
                        </div>
                        <div className="col-md-6 offset-md-3">
                            <div id="request" class="main_form">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <input className="contactus" placeholder="Nombre completo" type="type" name="Full Name" id="fulln" />
                                    </div>
                                    <div className="col-md-12">
                                        <input className="contactus" id="tel" placeholder="Número de teléfono" type="type" name="Phone Number" />
                                    </div>
                                    <div className="col-md-12">
                                        <input className="contactus" id="mail" placeholder="Email" type="type" name="Email" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea className="contactus" id="msj" placeholder="Mensaje" type="type" Message="Name"></textarea>
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="send_btn" onClick={enviarCorreo}>Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //footer
            }
            <Footer></Footer>

        </div>
    )
}

export default Home