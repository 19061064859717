import React from 'react'
import Header from "../../componentes/Header";
import { Link, NavLink } from "react-router-dom";
import './grafica.css'
import {
    faFileCsv,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Graf from '../../componentes/Graficas/StackedAreaChart'

const Graficas = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-end">
                        <div className='col-1'>
                            <Link to={'/Reportes'}>
                                <button type="button" className="btn btn-secondary">Reportes</button>
                            </Link>
                        </div>

                        <div className='col-1'>
                            <Link to={'/Graficas'}>
                                <button type="button" className="btn btn-secondary">Graficas</button>
                            </Link>
                        </div>
                        <div className='col-1'>
                            <Link to={'/Visualizador'}>
                                <button type="button" className="btn btn-secondary">Mapa</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <h2 className="titulo">Gráficas</h2>
                        <div className="col-md-6 col-lg-3 col-xl-2">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Tipo de Grafico</option>
                                <option value="1">Grafico 1</option>
                                <option value="2">Grafico 2</option>
                                <option value="3">Grafico 3</option>
                                <option value="3">Grafico 4</option>
                                <option value="3">Grafico 5</option>
                            </select>
                            <br />
                        </div>
                        <div className="col-md-6 col-lg-7 col-xl-8 offset-xl-1">
                            <div className="container-fluid shadows">
                               <Graf></Graf>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className='col-md-1 col-lg-1 col-xl-1'>
                            <FontAwesomeIcon
                                icon={faDownload}
                                size="3x" />
                        </div>
                        Descargar                       
                        <div className='col-md-2 col-lg-2 col-xl-2'>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-secondary">PDF</button>
                                <button type="button" class="btn btn-secondary">JPEG</button>
                                <button type="button" class="btn btn-secondary">PNG</button>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
    )
}

export default Graficas