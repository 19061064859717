import React from 'react'
import './registro.css'
import Header from "../../componentes/Header";

const Login = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center h-100">

                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <form>
                                {
                                    /*
                                    <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                                    <p className="lead fw-normal mb-0 me-3">Sign in with</p>
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                      <i className="fab fa-facebook-f"></i>
                                    </button>
                  
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                      <i className="fab fa-twitter"></i>
                                    </button>
                  
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                      <i className="fab fa-linkedin-in"></i>
                                    </button>
                                  </div>
                  
                                  <div className="divider d-flex align-items-center my-4">
                                    <p className="text-center fw-bold mx-3 mb-0">Or</p>
                                  </div>
                                    */
                                }



                                <div className="form-outline mb-4">
                                    <input type="text" id="nombres" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="nombres">Nombre(s)</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="paterno" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="paterno">Apellido Paterno</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="materno" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="materno">Apellido Materno</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="email" id="correo" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="correo">Correo electrónico</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="empresa" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="empresa">Nombre de la Empresa</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <select class="form-select form-control form-control-lg" aria-label="Default select example" id="tamanio">
                                        <option value="0">Selecciona un opción</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <label className="form-label" for="tamanio">Tamaño de la Empresa</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <select class="form-select form-control form-control-lg" aria-label="Default select example" id="pais">
                                        <option value="0">Selecciona un opción</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <label className="form-label" for="pais">Región/Pais</label>
                                </div>

                                <div className="text-center text-lg-start mt-4 pt-2">
                                    <button type="button" className="btn btn-primary btn-lg estilo" >Registrarse</button>
                                </div>

                            </form>
                        </div>
                        <div className="col-md-9 col-lg-6 col-xl-5">
                            <img src="/imgs/registro.png" className="img-fluid" alt="Sample image" />
                        </div>
                    </div>
                </div>
                {
                    /*
                    <div
                    className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
          
                    <div className="text-white mb-3 mb-md-0">
                      Copyright © 2020. All rights reserved.
                    </div>
          
          
          
                    <div>
                      <a href="#!" className="text-white me-4">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#!" className="text-white me-4">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#!" className="text-white me-4">
                        <i className="fab fa-google"></i>
                      </a>
                      <a href="#!" className="text-white">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
          
                  </div>
          
                    */

                }

            </section>
        </div>
    )
}

export default Login